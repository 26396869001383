import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/project/blog/src/components/BlogPost/BlogPost.js";
import TextLink from '@components/TextLink';
import List from '@components/List';
import ListItem from '@components/ListItem';
import VideoGif from '@components/VideoGif';
import Image from '@components/Image';
import NewsletterSignup from '@components/NewsletterSignup';
import Center from '@components/Center';
import RenderWhenOnscreen from '@components/RenderWhenOnscreen';
import LiveEditableCode from '@components/LiveEditableCode';
import Code from '@components/Code';
import Asterisk from '@components/Asterisk';
import registerPropertySrc from '@assets/images/rainbow-button/register-property-compatibility.png';
import profilingSrc from '@assets/images/rainbow-button/profiling.png';
import customPropsCode from './code/custom-props.example';
import customPropsCssCode from './code/custom-props-css.example';
import cssVarsConceptCode from './code/css-vars-concept.example';
import customPropsTransitionCode from './code/custom-props-transition.example';
import fakeNewsGradientCode from './code/fake-news-gradient.example';
import registerPropertyCode from './code/register-property.example';
import vanillaDemoCode from './code/vanilla-demo.example';
import hookConsumerCode from './code/hook-consumer.example';
import initialUseRainbowCode from './code/initial-use-rainbow.example';
import uniqueIdCode from './code/unique-id.example';
import prmCode from './code/prm.example';
import OldMethod from './components/OldMethod';
import GradientIdeaImage from './components/GradientIdeaImage';
import CasinoLights from './components/CasinoLights';
import DemoButton from './components/DemoButton';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <p>{`When I launched this blog a couple years back, I wanted to add a bit of flair to the newsletter subscribe button. My idea: an animated rainbow gradient for the background.`}</p>
    <p>{`I love gradients. After so many years of solid colors and flat design, I'm glad to see them making a comeback!`}</p>
    <p>{`It turned out that animating CSS gradients was a lot more trouble than I expected, and the result was a little underwhelming:`}</p>
    <RenderWhenOnscreen height={540} mdxType="RenderWhenOnscreen">
  <OldMethod mdxType="OldMethod" />
    </RenderWhenOnscreen>
    <p>{`Rather than animating the gradient directly, I created a very tall gradient, and translated it up within the button, resetting it once it neared the bottom. My trusty friend `}<inlineCode parentName="p">{`overflow: hidden`}</inlineCode>{` made sure that the excess wasn't visible to the user.`}</p>
    <p>{`This approach kinda works, but there are problems:`}</p>
    <ul>
      <li parentName="ul">{`The looping isn't entirely seamless. Subtle differences in performance across devices means that it can be noticeable when the position resets.`}</li>
      <li parentName="ul">{`It just doesn't look that great; I wanted something with an organic kind of flowing quality, and this just felt static and lifeless.`}</li>
    </ul>
    <p>{`Over the past couple years, I've given this button a lot of thought. It's been a long time coming, but after discovering a `}<em parentName="p">{`wild`}</em>{` new technique, I was finally able to come up with something I like.`}</p>
    <p>{`Without further ado, the new button:`}</p>
    <DemoButton mdxType="DemoButton" />
    <h2>{`Radial gradients to the rescue!`}</h2>
    <p>{`This new model uses a `}<inlineCode parentName="p">{`radial-gradient`}</inlineCode>{`: color seeps out from the top-left corner, shifting slowly through the rainbow, cascading across the button's surface.`}</p>
    <p>{`More precisely, there's a 3-color radial gradient anchored in the top-left corner. The colors would all be adjacent in the rainbow, and each "tick" of the animation would shift the colors down:`}</p>
    <GradientIdeaImage mdxType="GradientIdeaImage" />
    <p>{`The big difference here is that `}<em parentName="p">{`nothing is actually moving`}</em>{`. There's no translate happening on a 2D plane anymore. Instead, I'm sampling 3 colors from a 10-color rainbow palette, and each point in the gradient is slowly shifting to inherit the color in the previous point. The `}<inlineCode parentName="p">{`C3`}</inlineCode>{` point is always 1 color behind in the palette from the `}<inlineCode parentName="p">{`C2`}</inlineCode>{` point.`}</p>
    <p>{`This creates the `}<em parentName="p">{`illusion`}</em>{` of motion, similar to those casino or venue lights:`}</p>
    <CasinoLights mdxType="CasinoLights" />
    <blockquote>
      <p parentName="blockquote">{`This is also similar to how sound waves move through the air! I created an explorable explanation that `}<a parentName="p" {...{
          "href": "https://pudding.cool/2018/02/waveforms/"
        }}>{`demonstrates this concept`}</a>{`.`}</p>
    </blockquote>
    <h2>{`Animating gradients`}</h2>
    <p>{`So the game plan was coming together:`}</p>
    <ul>
      <li parentName="ul">{`I'd create a palette of 10 rainbow colors.`}</li>
      <li parentName="ul">{`I'd set a gradient to hold a moving window of 3 colors.`}</li>
      <li parentName="ul">{`I'd run an interval that would update the gradient every second, shifting each color by 1 spot.`}</li>
      <li parentName="ul">{`I'd tween between the colors in each spot. On every frame, the colors should inch towards their next value.`}</li>
    </ul>
    <p>{`That last step was the trickiest. Unfortunately, you can't use `}<inlineCode parentName="p">{`transition`}</inlineCode>{` to interpolate between background gradients. The following snippet doesn't work:`}</p>
    <Code lang="css" mdxType="Code">{fakeNewsGradientCode}</Code>
    <p>{`I `}<em parentName="p">{`could`}</em>{` do it all in JS. I could set up a `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{` loop that splits each color transition into ~60 incremental steps. I didn't like this idea, it felt overengineered. Plus, because it would all be happening on the main thread in Javascript, the animation could become choppy during busy periods.`}</p>
    <p>{`I wanted to do the interpolating in CSS. And happily, I found a way 😊`}</p>
    <h2>{`Custom properties (AKA CSS variables)`}</h2>
    <p>{`For a while now, CSS has had variables. At first blush, they look a lot like the variables you'd see in SASS or LESS, but unlike preprocessors, variables are still in the code at runtime. This makes them much more powerful, as we'll soon see!`}</p>
    <p>{`Here's how you can use CSS custom properties in a gradient:`}</p>
    <Code lang="css" mdxType="Code">{customPropsCssCode}</Code>
    <p>{`We can use inline styles to set this on React elements, like so:`}</p>
    <LiveEditableCode inline id="custom-css-props" code={customPropsCode} split={[70, 30]} mdxType="LiveEditableCode" />
    <p>{`On their own, this doesn't actually help us. We still can't apply `}<inlineCode parentName="p">{`transition`}</inlineCode>{` directly on `}<inlineCode parentName="p">{`background`}</inlineCode>{`. But it gets us one step closer 🕵🏻‍♂️`}</p>
    <h2>{`🎩 CSS Houdini`}</h2>
    <p>{`CSS Houdini is a wide-ranging set of upcoming CSS enhancements predicated on one idea: developers should be able to create their own CSS features.`}</p>
    <p>{`For example, CSS doesn't have any built-in way to do masonry layouts. Wouldn't it be cool if you could build it, plugging in directly to CSS mechanisms, and then access it with `}<inlineCode parentName="p">{`display: masonry`}</inlineCode>{`?`}</p>
    <p>{`For another example: projects like Babel allow us to "polyfill" (most) missing features in JS, because we can mimic those new features using an earlier version of the language. But we can't polyfill (most) CSS features. Houdini will allow us to polyfill in missing CSS, by giving us access to the internal wiring of the CSS engine.`}</p>
    <p>{`CSS Houdini is a huge project, already years into research and development, and I expect it'll shape the future of web development in exciting and unpredictable ways. For today, though, I'd like to focus on one relatively small but incredibly cool part of this: `}<em parentName="p">{`animated custom properties`}</em>{`.`}</p>
    <h2>{`Animated custom properties`}</h2>
    <p>{`In CSS, a "property" is something you can assign a value. `}<inlineCode parentName="p">{`display`}</inlineCode>{` and `}<inlineCode parentName="p">{`transform`}</inlineCode>{` and `}<inlineCode parentName="p">{`color`}</inlineCode>{` are all examples of properties. Why, then, are `}<em parentName="p">{`variables`}</em>{` in CSS called `}<em parentName="p">{`custom properties`}</em>{`? Aren't they a totally different concept?`}</p>
    <p>{`Actually, they're more similar than I realized. It's better to think of CSS variables as your own properties, like display and transform.`}</p>
    <Code lang="css" mdxType="Code">{cssVarsConceptCode}</Code>
    <p>{`Here's the wild, mind-blowing part: you can `}<em parentName="p">{`transition custom properties`}</em>{`:`}</p>
    <Code lang="css" mdxType="Code">{customPropsTransitionCode}</Code>
    <p>{`We're not telling the browser to animate the `}<em parentName="p">{`background property`}</em>{`, we're telling the browser to animate our `}<em parentName="p">{`custom property`}</em>{`. And then we're using that custom property in our background gradient. Amazingly, the `}<inlineCode parentName="p">{`var()`}</inlineCode>{` keyword is reactive, causing the background to re-paint whenever the value changes, even when that value is being tweened by `}<inlineCode parentName="p">{`transition`}</inlineCode>{`.`}</p>
    <p>{`My mind is still buzzing with the possibilities. CSS custom properties are so much cooler than I realized, and Houdini gives us downright magical powers ✨🧙✨`}</p>
    <h3>{`One more piece: registering the property`}</h3>
    <p>{`There's one more thing we need to do before this will actually work. We need to tell the browser what the `}<em parentName="p">{`type`}</em>{` of our custom property is.`}</p>
    <p>{`Should the browser treat it as a color? A length? An angle? We need to be explicit about it, so that the browser knows how to interpolate changes.`}</p>
    <p>{`We do this in JS with the following method:`}</p>
    <Code lang="js" mdxType="Code">{registerPropertyCode}</Code>
    <h2>{`A vanilla JS demo`}</h2>
    <p>{`In a bit, we'll see how React hooks let us package this up rather nicely. First, though, I wanted to share the raw JS code, for folks using a different framework, or no framework at all:`}</p>
    <Code lang="js" maxHeight="60vh" mdxType="Code">
  {vanillaDemoCode}
    </Code>
    <h2>{`Hook it up ⚛️`}</h2>
    <p>{`One of the neat things about React hooks is that they give the developer more control around how different ideas are expressed. Custom hooks let us shove a bunch of stuff in a box, and it's up to us to draw the boxes. We can choose whether we want to optimize for reusability, or clarity, or anything else.`}</p>
    <p>{`In this case, I'd like to keep things friendly. I'm OK sacrificing a bit of power or flexibility in exchange for a no-fuss no-frills `}<inlineCode parentName="p">{`useRainbow`}</inlineCode>{` hook.`}</p>
    <h3>{`State and API`}</h3>
    <p>{`Initially, I was thinking I would hold the current colors in state, but it occurred to me that the colors are derived data; the `}<em parentName="p">{`real`}</em>{` bit of state is the current interval count.`}</p>
    <p>{`If I'm on the 5th cycle, for example, I know that my colors will be the 5th, 6th, and 7th colors in my 10-color palette. Because the palette is static, I can just track that number, and use it to derive the colors.`}</p>
    <p>{`The next thing I wanted to figure out was the hook's interface. I started by writing the component that will consume this hook. I like just making up whatever API seems ideal for the component that uses it. Consumer-driven development.`}</p>
    <Code lang="js" maxHeight="60vh" mdxType="Code">
  {hookConsumerCode}
    </Code>
    <p>{`With that in mind, here's the initial version of this hook:`}</p>
    <Code lang="js" maxHeight="60vh" mdxType="Code">
  {initialUseRainbowCode}
    </Code>
    <blockquote>
      <p parentName="blockquote">{`*`}{` `}<inlineCode parentName="p">{`useIncrementingNumber`}</inlineCode>{` is a custom hook that spits out a new, ever-increasing number, based on a provided interval delay. It's based off of Dan Abramov's `}<a parentName="p" {...{
          "href": "https://overreacted.io/making-setinterval-declarative-with-react-hooks/"
        }}>{`setInterval hook`}</a>{`. You can view its source `}<a parentName="p" {...{
          "href": "https://github.com/joshwcomeau/blog/blob/master/src/hooks/use-incrementing-number.hook.js"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>
    <p>{`I like this approach, because there's a clear separation of duties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`useRainbow`}</inlineCode>{` is in charge of generating and managing the colors, but has no vote in what they're used for.`}</li>
      <li parentName="ul">{`The component, `}<inlineCode parentName="li">{`MagicRainbowButton`}</inlineCode>{`, doesn't know anything about where these colors came from or when they update, but decides what to do with them.`}</li>
    </ul>
    <p>{`There's one thing that makes my spidey-sense tingle a bit; it's pretty surprising that `}<inlineCode parentName="p">{`useRainbow`}</inlineCode>{` secretly registers global CSS custom properties. In fact, registering a global value from within an instanced component is going to be problematic! We'll tackle this, and some other lingering issues, in the next section.`}</p>
    <h2>{`Making this production-ready`}</h2>
    <p>{`Before you start shipping rainbow buttons all over your law firm's website or your accounting software, there are a couple things we need to think about.`}</p>
    <h3>{`Global properties and duplicate components`}</h3>
    <p>{`The biggest problem with our current implementation is that it violates a core React principle: every instance of a component should be independent. We should be able to render as many copies of it as we want, without them interfering with each other.`}</p>
    <p>{`If we try to render two copies of our `}<inlineCode parentName="p">{`MagicRainbowButton`}</inlineCode>{` on the same page, we get this error:`}</p>
    <blockquote>
      <p parentName="blockquote">{`InvalidModificationError: Failed to execute 'registerProperty' on 'CSS': The name provided has already been registered.`}</p>
    </blockquote>
    <p>{`This is because the CSS custom properties registry is a global object; all of our component instances are sharing the same global namespace! And right now, they're both trying to register the same names.`}</p>
    <p>{`I got around this by creating a unique ID for each React component, and storing it with a `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` hook:`}</p>
    <Code lang="js" mdxType="Code">{uniqueIdCode}</Code>
    <p>{`This also makes me feel better about the "secret side-effects in hooks" thing. A bit of randomness rules out the risk of name collisions, letting us pretend that it isn't actually global.`}</p>
    <h3>{`Browser support`}</h3>
    <p>{`Houdini is super bleeding-edge, and this is reflected in its browser support: At the time of writing, `}<inlineCode parentName="p">{`CSS.registerProperty`}</inlineCode>{` is only supported by Chrome 78+, and Opera 65+.`}</p>
    <img alt="Table showing the lack of browser support, aside from Chrome and Opera" src={registerPropertySrc} style={{
      width: '100%'
    }} />
    <p>{`My solution? Bail out of the hook early, if `}<inlineCode parentName="p">{`window.CSS`}</inlineCode>{` or `}<inlineCode parentName="p">{`CSS.registerProperty`}</inlineCode>{` aren't found, and return the first 3 colors. Other browsers won't get the animation, but they'll still get a nice gradient! And our React component doesn't have to change at all 💯`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: IE11 doesn't support custom properties at all, so if you need to support it, you'll need to set a fallback background gradient, using hardcoded color values instead of custom properties`}</p>
    </blockquote>
    <h3>{`Performance`}</h3>
    <p>{`Last year, I `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=DNGGzwmfouU"
      }}>{`gave a talk`}</a>{` about animation/interaction performance. In that talk, I mention that there are two "gold standard" properties: `}<em parentName="p">{`opacity`}</em>{` and `}<em parentName="p">{`transform`}</em>{`. Those two properties perform way better than other properties, because they don't have to paint on every frame, they can be manipulated directly by the graphics card as a texture, shimmying around without the CPU doing any work.`}</p>
    <p>{`In that talk, I `}<em parentName="p">{`also`}</em>{` advocated for breaking this rule, as long as you're measuring. With a `}<em parentName="p">{`6x throttle`}</em>{` on my CPU, I fired up the profiler:`}</p>
    <img alt="A very empty profile, with it easily hitting 60fps" src={profilingSrc} style={{
      width: '100%'
    }} />
    <p>{`It is true that this technique involves a repaint on every frame, and that repaints can be slow… but in this case, the amount of repainting is tiny. The repaint takes `}<em parentName="p">{`~0.3 milliseconds`}</em>{`, which is about 2% of our budget if we want to hit 60fps.`}</p>
    <p>{`Animating properties like `}<inlineCode parentName="p">{`height`}</inlineCode>{` is often very slow, because it involves both a `}<em parentName="p">{`layout`}</em>{` and `}<em parentName="p">{`paint`}</em>{` step, and because the number of pixels involved can be very large. In this case, there's no layout step, and the paint step is quick and targeted 💫`}</p>
    <h3>{`Accessibility`}</h3>
    <p>{`Whimsical touches are great, but not when they come at the expense of usability.`}</p>
    <p>{`Certain types of animations can be problematic for folks with vestibular disorders—they can trigger vertigo, nausea, headaches, and other nasty symptoms.`}</p>
    <p>{`Browsers have been hard at work implementing support for a `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/updates/2019/03/prefers-reduced-motion"
      }}>{`"prefers-reduced-motion"`}</a>{` media query. This query relies on a Windows/MacOS setting, and lets users express that they would like to disable animations.`}</p>
    <p>{`The browser support for this media query has gotten dramatically better, and supports Chrome, Firefox, Safari, and (soon) Edge. We'll set it up so that we're only enabling animations for folks who have selected "no preference" on motion, the default value:`}</p>
    <Code lang="js" mdxType="Code">{prmCode}</Code>
    <p>{`This method might be a little counter-intuitive—wouldn't we want to `}<em parentName="p">{`disable`}</em>{` the animation for folks who express a preference?—but it works out to be the same thing in most cases. The exception is for folks using older browsers like Internet Explorer; in their case, the media query doesn't exist. Doing it this way means that people in those browsers `}<em parentName="p">{`won't`}</em>{` see the animation. Better to take the safer assumption.`}</p>
    <p>{`In addition to motion, we also need to think about color contrast. Will folks with vision impairments be able to read the text in the button? I added a bit of text shadow, and darkened the warm end of the spectrum. Truthfully, it may still be too low-contrast for certain periods in the animation, but I'm confident it's legible most of the time, and the animation shifts quickly.`}</p>
    <h1>{`One more subscribe button`}</h1>
    <p>{`It's a brand new year, and one of my goals for 2020 is to produce many high-quality interactive blog posts like this one. My newsletter is the best way to find out when something new is posted.`}</p>
    <p>
  I know I've thrown a lot of "subscribe" buttons at you this post
  <Asterisk mdxType="Asterisk">
    A blog post all about newsletter subscriptions, what a genius marketing
    idea!
  </Asterisk>, but this last one is for real. Won't you join my newsletter?
    </p>
    <NewsletterSignup id="end-of-rainbow-page" hideDisclaimer mdxType="NewsletterSignup" />
    <p>{`If you're keen to build your own rainbow button, the source code for this one might come in handy. This blog is open-source, so you can find it `}<a parentName="p" {...{
        "href": "https://github.com/joshwcomeau/blog/blob/master/src/components/MagicRainbowButton/"
      }}>{`on Github`}</a>{`.`}</p>
    <p>{`If you do wind up using this effect somewhere, `}<em parentName="p">{`I'd love to see it`}</em>{`! Hit me up `}<a parentName="p" {...{
        "href": "https://twitter.com/JoshWComeau"
      }}>{`on Twitter`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:joshwcomeau@gmail.com"
      }}>{`by email`}</a>{` and let me know!`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      